import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Styles = styled.div``;

const SelectTipoTarea = ({
    id,
    value,
    tipoTrabajo,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    defaultProps,
    isLoading,
    placeHolder
}) => {
    const items = useSelector(state => state.default.tiposTareas);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const isTipoTrabajoDisabled = (opt) => {
        if (!tipoTrabajo) {
            return false;
        }
        let isDisable = false;
        switch (tipoTrabajo) {
            case 'NORMAL':
                break;
            case 'ASISTENICA':
                isDisable = opt.value !== 1;
                break;
            case 'RETIRO':
                isDisable = opt.value !== 2;
                break;
            case 'INSTALACION':
                isDisable = opt.value !== 3;
                break;
            default:
                break;
        }
        return isDisable;
    };

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id,
                label: item.nombre
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value) || value?.id || value?.value){
            setSelectedValue(newOptions.find((opt) => opt.value === value.id || opt.value === value.value));
        }
    }, [items,isLoading]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                isDisabled={disabled}
                inputId={id}
                name={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder={placeHolder === null || placeHolder === undefined ? ("Seleccione...") : placeHolder}
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isOptionDisabled={isTipoTrabajoDisabled}
            />
        </Styles>
    );
};

SelectTipoTarea.defaultProps = {
    isClearable: false
};

export default SelectTipoTarea;
