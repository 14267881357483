import React, {useState, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connect } from "react-redux";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {BoxPrefactibilidad, Button, InputCheck, MapBase, Poligono, SelectLugar, TablePaginated, DateRange, MarkerTarea, SelectEquipo, SelectTipoCovertura} from '@app/components/index';
import * as MapaAction from "@app/store/actions/mapas";
import useCurrentLocation from "@app/hooks/useCurrentLocation";
import MapaFunction from "@app/functions/MapaFunction";
import FechaFunction from "@app/functions/FechaFunction";
import {Collapse} from "react-bootstrap";
import {showModal} from 'react-redux-modal-provider';
import ModalFiltroCalendar from "@app/modals/filtro/ModalFiltroCalendar";

function MapaIngenieriaPage ({
    poligonos,
    tareas,
    esMostrarPaneles,
    esMotrarTareas,

    recuperarPoligono,
    recuperarTarea,
    cambiarMostrarPanel,
    cambiarMostrarTarea,
}) {
    AOS.init();
    const [t] = useTranslation();
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const [mapLocation, setMapLocation] = useState(currentLocation);
    const [mapEstado, setMapEstado] = useState("");
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState(currentLocation);
    const [poligonosSelect, setPoligonosSelect] = useState(null);
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek());
    const [filtros, setFiltros] = useState({
        tareaCategoria: 'NORMAL,PYME 1',
        tipoTareaId: '1,2,3',
        estadoTareaId: '1,2,3,5'
    });

    const recuperar = () => {
        const data = {
            coordenadas: `${mapLocation.lat},${mapLocation.lng}`,
            coordenadas_relativa: `${prefactibilidadLocation.lat},${prefactibilidadLocation.lng}`,
        }
        recuperarPoligono(data);
    }

    const recuperarTareas = (data) => {
        recuperarTarea(data).then(()=>{})
    }

    const onChange = (newCoordinates) => {
        setMapLocation(newCoordinates);
    }

    const onChangePrefactibilidad = (newCoordinates) => {
        setPrefactibilidadLocation(newCoordinates);
    }

    const onClickMap = (newCoordinates) => {
        setPrefactibilidadLocation(newCoordinates);
        setMapLocation(newCoordinates);
    }

    const onSelectLugar = ({item}) => {
        let newCoord;
        const { coordenadas } = item;
        if(coordenadas){
            newCoord = MapaFunction.gJsonToGoogleMaps(coordenadas).pop();
        } else {
            newCoord = {lat:item.latitud,lng:item.longitud};
        }
        setMapLocation(newCoord);
        setPrefactibilidadLocation(newCoord);
    }

    const onSearchBox = ({coordenadas}) => {
        setPrefactibilidadLocation(coordenadas);
        setMapLocation(coordenadas);
    }

    const onSelectPanel = (newPoligono) => {
        setPoligonosSelect(newPoligono);
    }

    const onClickFiltrar = () => {
        showModal(ModalFiltroCalendar, { item:filtros, onConfirm:setFiltros });
    };

    useEffect(() => {
        const fechaIni = FechaFunction.format(rangeDate.start,'yyyy/MM/dd');
        const fechaFin = FechaFunction.format(rangeDate.end,'yyyy/MM/dd');
        const data = {
            fechaIni,
            fechaFin
        };
        recuperarTareas(data);
        return () => {
        }
    }, [filtros,rangeDate])

    useEffect(() => {
        recuperar();
    }, [mapLocation,prefactibilidadLocation]);

    const columns = React.useMemo(
        () => [
            {
                width: 10,
                MaxWidth: 45,
                Header: 'Distacia',
                accessor: 'distancia',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    const { original } = row;
                    return (
                        <div className="text-right">
                            {
                                original.distancia === 0.0 ? ('-'):(
                                    Number(original.distancia).toFixed(1)
                                )
                            }
                        </div>
                    );
                }
            },
            {
                Header: 'Panel',
                accessor: 'nombre',
            },
            {
                Header: 'Tecnologia',
                accessor: 'tipoEquipo',
                ClassName: 'm-0',
                Width: 100,
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectEquipo
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) => {
                    return row.original.tipoEquipo;
                },
                filter: 'includes'
            },
            {
                Header: 'Estado',
                accessor: 'tipoCovertura',
                ClassName: 'm-0',
                Width: 130,
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectTipoCovertura
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) =>{
                    if(row.original.tipoCovertura === "DISPONIBLE"){
                        return (
                            <div className="text-center">
                                <button type="button" className="btn btn-success btn-xs">Disponible</button>
                            </div>
                        );
                    }
                    if(row.original.tipoCovertura === "MEDIO"){
                        return (
                                <div className="text-center">
                                    <button type="button" className="btn btn-warning btn-xs">Medio</button>
                                </div>

                        );
                    }
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-danger btn-xs">Saturado</button>
                        </div>
                    );
                }
            },
            {
                Header: 'Mostrar',
                accessor: 'id',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Button
                            size="sm"
                            theme="light"
                            icon="fa fa-map-marker-alt"
                            onClick={() => onSelectPanel(row.original)}
                        >
                            Mapa
                        </Button>
                    );
                }
            }
        ],
        []
    );
    const unionPoligonos = MapaFunction.unirPoligonos(poligonos);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center" data-aos="fade-up">
                        <h2 className="">Mapa</h2>
                    </div>
                    <div className="col bg-white rounded shadow-sm border" data-aos="fade-right">
                        <div className="p-3">
                                    <label htmlFor="lugarId">Lugar</label>
                                <div className="form-group">
                                        <SelectLugar id="lugarId" onChange={onSelectLugar}></SelectLugar>
                                </div>
                        </div>
                    </div>
                    <div className="col pt-4 border rounded shadow-sm bg-white text-center" data-aos="fade-right">
                        <div className="border-dark">
                                <InputCheck checked={esMostrarPaneles} label='Mostrar Paneles' onClick={cambiarMostrarPanel} />
                                <InputCheck checked={esMotrarTareas} label='Mostrar Tareas' onClick={cambiarMostrarTarea} />
                        </div>
                    </div>
                    {/* <div className="col pt-4 border rounded shadow-sm bg-white text-center" data-aos="fade-right">
                        <div className="border-dark">
                                <button className="btn btn-success" type="button">Disponible</button>
                                <button className="btn btn-warning" type="button">Medio</button>
                                <button className="btn btn-danger" type="button">Saturado</button>
                        </div>
                    </div> */}
                    <Collapse in={esMotrarTareas}>
                        <div className="col border bg-white shadow-sm rounded text-center py-1">
                            <p>Fecha</p>
                            <DateRange defaultValue={rangeDate} onChange={(f)=> setRangeDate(f)} />
                            <Button className="mt-2" onClick={onClickFiltrar}>Abrir Filtros</Button>
                        </div>
                    </Collapse>
                </div>
            </div>
            <div className="container-fluid rounded-lg p-lg-1 mt-lg-1" data-aos="fade-right">
                <div className="row">
                        <div className="col-lg-4 border bg-white rounded col-12 mt-md-5 mt-lg-0 p-3 order-2 order-lg-1">
                            <TablePaginated
                                isShowHeader={false}
                                columns={columns}
                                data={poligonos}
                            />
                        </div>
                        <div className="col-lg-8 pt-3 offset-1 col-10 offset-lg-0 mt-2 mt-lg-0 order-1 order-lg-2" data-aos="fade-right">
                            <MapBase
                                id="mapaComercial"
                                mapContainerStyle={{
                                    width: '100%',
                                    height: '70vh'
                                }}
                                options={{
                                    maxZoom: 17,
                                    minZoom: 10,
                                }}
                                zoom={15}
                                coordinates={mapLocation}
                                onChange={onChange}
                                onClick={onClickMap}
                                onSearch={onSearchBox}
                            >
                            {
                                esMostrarPaneles && unionPoligonos.map(poly=>{
                                    return <Poligono item={poly} key={poly.id} />
                                })
                            }
                            {
                                poligonosSelect && (
                                    <Poligono item={poligonosSelect} key={poligonosSelect.id} selected />
                                )
                            }
                            {
                                esMotrarTareas && tareas.map(tarea=>{
                                    return <MarkerTarea item={tarea} key={tarea.id} />
                                })
                            }
                            <BoxPrefactibilidad onChange={onChangePrefactibilidad} coordinates={prefactibilidadLocation} />
                            </MapBase>
                        </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    poligonos: state.mapas.poligonos,
    tareas: state.mapas.tareas,
    prefactibilidad: state.mapas.prefactibilidad,
    esMostrarPaneles: state.mapas.esMostrarPaneles,
    esMotrarTareas: state.mapas.esMotrarTareas,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPoligono: (data) => dispatch(MapaAction.recuperarPoligonos(data)),
    recuperarTarea: (data) => dispatch(MapaAction.recuperarTareas(data)),
    cambiarMostrarPanel: (data) => dispatch(MapaAction.cambiarMostrarPaneles(data)),
    cambiarMostrarTarea: (data) => dispatch(MapaAction.cambiarMostrarTareas(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapaIngenieriaPage);