import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    Button,
    ButtonToggle,
    InputEditor,
    Select,
    SelectMotivoTarea,
} from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';
import Collapse from 'react-bootstrap/Collapse';

const schema = Yup.object({
    tipoTareaId: Yup.mixed().required(),
    motivoTareaId: Yup.mixed().required(),
    crearContrato: Yup.mixed().when("tipoTareaId", {
        is: value => {
            return Number(value) === 3
        },
        then: Yup.mixed().required(),
        otherwise: Yup.mixed(),
    })

});

function TareaForm2({
    contacto,
    iniciarContacto,
    habilitarCrearContrato,
    nextStep,
    goToStep,
    previousStep,
}) {
    const {register, handleSubmit, errors, setValue,watch } = useForm({
        resolver: yupResolver(schema)
    });
    const tipoTareas = [
        {
            name: 'ASISTENCIA',
            value: '1',
        },
        {
            name: 'RETIRO',
            value: '2',
        },        {
            name: 'INSTALACION',
            value: '3',
        }
    ];
    const tipoTareaId = watch('tipoTareaId');
    const [template, setTemplate] = useState('');

    useEffect(() => {
        register('tipoTareaId');
        register('motivoTareaId');
        register('crearContrato');
        register('motivoTareaObservaciones');
    }, [register]);

    useEffect(() => {
        setTemplate('');
        setValue(
            'motivoTareaId',
            null
        );
        setValue(
            'crearContrato',
            null
        );
        if(tipoTareaId === 3){
            habilitarCrearContrato(true);
        } else {
            habilitarCrearContrato(false);
        }
        return () => {

        }
    }, [tipoTareaId])

    const onSubmit = (data) => {
        const form = {
            ...contacto,
            ...data,
        };
        iniciarContacto(form)
        if(tipoTareaId === "3" || tipoTareaId === 3 ){
            nextStep();
        }else{
            goToStep(5);
        }
    };

    return (
    <>
        <div className="container border shadow-sm rounded-lg bg-white p-3">
            <div className="row">
                <div className="col-12 my-2 text-center-md col-md-12 col-lg-12 ml-lg-3  my-lg-2">
                    <h2>Tareas Parte 2</h2>
                </div>
                <div className="col-md-6 col-lg-3 ml-lg-3 pt-lg-2">
                    <div className="form-group">
                            <h6>Tipo de Tarea</h6>

                        <ButtonToggle
                            name="tipoTareaId"
                            items={tipoTareas}
                            checked={3}
                            onChange={(opt) => {
                                setValue('tipoTareaId', Number(opt));
                            }}
                        />
                        {errors.tipoTareaId ? (
                            <div className="default-invalid-feedback">
                                {errors.tipoTareaId?.message}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 ml-lg-4">
                    <div className="form-group">
                        <label
                            className="label-control"
                            htmlFor="motivoTareaId"
                        >
                            Motivo de la Tarea
                        </label>
                        <SelectMotivoTarea
                            id="motivoTareaId"
                            tipoTareaId={tipoTareaId}
                            onChange={(opt) => {
                                setValue(
                                    'motivoTareaId',
                                    opt.value
                                );
                                setValue(
                                    'motivoTareaObservaciones',
                                    opt.descripcion
                                );
                                setTemplate(opt.descripcion);
                            }}
                        />
                        {errors.motivoTareaId ? (
                            <div className="default-invalid-feedback">
                                {errors.motivoTareaId?.message}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Collapse in={tipoTareaId === 3}>
                    <div className="col-md-6 col-lg-4 ml-lg-3">
                        <div className="form-group">
                            <label
                                className="label-control"
                                htmlFor="crearContrato"
                            >
                                Desea crear contrato
                            </label>
                            <Select
                                id="crearContrato"
                                isInvalid={errors.crearContrato}
                                options={[
                                    {
                                        label:'SI',
                                        value:'SI'
                                    },
                                    {
                                        label:'NO',
                                        value:'NO'
                                    },
                                ]}
                                onChange={(opt)=>setValue('crearContrato',opt.value)}
                            />
                            {errors.crearContrato ? (
                                <div className="invalid-feedback">
                                    {errors.crearContrato?.message}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Collapse>
                <Collapse in={tipoTareaId === 1 || tipoTareaId === 2}>
                    <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                            <label
                                className="label-control"
                                htmlFor="motivoTareaObservaciones"
                            >
                                Observaciones
                            </label>
                                <InputEditor
                                    id="motivoTareaObservaciones"
                                    template={template}
                                    disabled={!(tipoTareaId > 0)}
                                    onChange={(html) => {
                                        setValue(
                                            'motivoTareaObservaciones',
                                            html
                                            );
                                        }}
                                />
                                {errors.motivoTareaObservaciones ? (
                                    <div className="default-invalid-feedback">
                                        {
                                            errors.motivoTareaObservaciones
                                            ?.message
                                        }
                                    </div>
                                ) : null}
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="col-12 my-lg-4 col-lg-12">
                <div className="row">

                    <div className="offset-6 col-6 offset-md-8 col-md-4 offset-lg-10 col-lg-2 float-right">
                        <Button className="btn-secondary" onClick={previousStep}>
                            Anterior
                        </Button>
                        <Button
                            className="btn btn-primary"
                            theme="primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Continuar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

TareaForm2.defaultProps = {
    item: {
        motivoTarea: {
            id: 0,
        }
    },
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
    habilitarCrearContrato: (is) => dispatch(ContactosAction.habilitarCrearContrato(is)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm2);