import React, {useState, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connect } from "react-redux";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {BoxPrefactibilidad, Button, DateRange, InputCheck, MapBase, Poligono, SelectLugar, MarkerTarea} from '@app/components/index';
import './MapaComercial.scss';
import * as MapaAction from "@app/store/actions/mapas";
import useCurrentLocation from "@app/hooks/useCurrentLocation";
import MapaFunction from "@app/functions/MapaFunction";
import {Collapse} from "react-bootstrap";
import {showModal} from 'react-redux-modal-provider';
import FechaFunction from "@app/functions/FechaFunction";
import ModalFiltroCalendar from "@app/modals/filtro/ModalFiltroCalendar";

function MapaComercialPage ({
    poligonos,
    tareas,
    esMostrarPaneles,
    esMotrarTareas,

    recuperarPoligono,
    recuperarTarea,
    cambiarMostrarPanel,
    cambiarMostrarTarea,
}) {
    AOS.init();
    const [t] = useTranslation();
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const [mapLocation, setMapLocation] = useState(currentLocation);
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState(currentLocation);
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek());
    const [filtros, setFiltros] = useState({
        tareaCategoria: 'NORMAL,PYME 1',
        tipoTareaId: '1,2,3',
        estadoTareaId: '1,2,3,5'
    });

    const recuperar = (coord) => {
        const query = {
            coordenadas: `${coord.lat},${coord.lng}`,
        }
        recuperarPoligono(query);
    }
    const recuperarTareas = (data) => {
        recuperarTarea(data).then(()=>{})
    }

    const onChange = (newCoordinates) => {
        recuperar(newCoordinates);
    }

    const onClickMap = (newCoord) => {
        setPrefactibilidadLocation(newCoord);
    }

    const onSelectLugar = ({item}) => {
        let newCoord;
        const { coordenadas } = item;
        if(coordenadas){
            newCoord = MapaFunction.gJsonToGoogleMaps(coordenadas).pop();
        } else {
            newCoord = {lat:item.latitud,lng:item.longitud};
        }
        setMapLocation(newCoord);
        setPrefactibilidadLocation(newCoord);
        recuperar(newCoord);
    }

    const onSearchBox = ({coordenadas}) => {
        setPrefactibilidadLocation(coordenadas);
        recuperar(coordenadas);
    }

    const onClickFiltrar = () => {
        showModal(ModalFiltroCalendar, { item:filtros, onConfirm:setFiltros });
    };

    useEffect(() => {
        const fechaIni = FechaFunction.format(rangeDate.start,'yyyy/MM/dd');
        const fechaFin = FechaFunction.format(rangeDate.end,'yyyy/MM/dd');
        const data = {
            fechaIni,
            fechaFin
        };
        recuperarTareas(data);
        return () => {
        }
    }, [filtros,rangeDate])

    useEffect(() => {
        recuperar(currentLocation);
    }, []);

    const unionPoligonos = MapaFunction.unirPoligonos(poligonos);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center my-1" data-aos="fade-up">
                        <h2>Mapa</h2>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 border bg-white rounded shadow-sm p-3" data-aos="fade-right">
                            Lugar
                                <SelectLugar id="lugarId" onChange={onSelectLugar}></SelectLugar>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 border bg-white shadow-sm rounded text-center pt-4 pb-3" data-aos="fade-right">
                                <InputCheck checked={esMostrarPaneles} label='Mostrar Paneles' onClick={cambiarMostrarPanel} />
                                <InputCheck checked={esMotrarTareas} label='Mostrar Tareas' onClick={cambiarMostrarTarea} />
                    </div>
                    <Collapse in={esMotrarTareas}>
                        <div className="col-12 col-md-4 col-lg-4 border bg-white shadow-sm rounded text-center p-2">
                            <p>Fecha</p>
                            <DateRange defaultValue={rangeDate} onChange={(f)=> setRangeDate(f)} />
                            <Button className="mt-2" onClick={onClickFiltrar}>Abrir Filtros</Button>
                        </div>
                    </Collapse>
                </div>
                <div className="row">
                    <div className="offset-1 offset-lg-0 mt-3 mt-lg-0 border bg-white rounded col-10 col-lg-12 mt-lg-2" data-aos="fade-right">
                        <MapBase
                            id="mapaComercial"
                            mapContainerStyle={{
                                width: '100%',
                                height: '70vh'
                            }}
                            options={{
                                maxZoom: 19,
                                minZoom: 14,
                            }}
                            zoom={15}
                            coordinates={mapLocation}
                            onChange={onChange}
                            onClick={onClickMap}
                            onSearch={onSearchBox}
                        >
                        {
                            esMostrarPaneles && unionPoligonos.map(poly=>{
                                return <Poligono item={poly} key={poly.id} />
                            })
                        }
                        {
                            esMotrarTareas && tareas.map(tarea=>{
                                return <MarkerTarea item={tarea} key={tarea.id} />
                            })
                        }
                        <BoxPrefactibilidad coordinates={prefactibilidadLocation} />
                        </MapBase>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    poligonos: state.mapas.poligonos,
    tareas: state.mapas.tareas,
    prefactibilidad: state.mapas.prefactibilidad,
    esMostrarPaneles: state.mapas.esMostrarPaneles,
    esMotrarTareas: state.mapas.esMotrarTareas,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPoligono: (data) => dispatch(MapaAction.recuperarPoligonos(data)),
    recuperarTarea: (data) => dispatch(MapaAction.recuperarTareas(data)),
    cambiarMostrarPanel: (data) => dispatch(MapaAction.cambiarMostrarPaneles(data)),
    cambiarMostrarTarea: (data) => dispatch(MapaAction.cambiarMostrarTareas(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapaComercialPage);