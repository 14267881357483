import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {showModal} from 'react-redux-modal-provider';
import usePermisos from '@app/hooks/usePermisos';
import {
    Button,
    DateSingle,
    SelectPeriodo,
    SelectTipoTrabajo,
    SelectZona,
    InputCheck,
    SelectLugar,
    ModalConfirm
} from '@app/components/index';
import CuadrillaLugaresService from '@app/services/cuadrillaLugares.service';
import { connect } from 'react-redux';

const schema = Yup.object({
    periodo: Yup.string().required(),
    lugarMTD: Yup.number().when('periodo', {
        is: (opt) => ['TM', 'TT', 'TD', 'TC'].indexOf(opt) >= 0,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable()
    }),
    tipoTarea: Yup.string().when('periodo', {
        is: (opt) => ['TM', 'TT', 'TD', 'TC'].indexOf(opt) >= 0,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable()
    }),
    lugarCompuesto: Yup.number().when('periodo', {
        is: (opt) => ['TC'].indexOf(opt) >= 0,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable()
    }),
    tipoTareaCompuesto: Yup.string().when('periodo', {
        is: (opt) => ['TC'].indexOf(opt) >= 0,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable()
    })
});

function ModalCuadrillaLugarEditar({show, hideModal, item, onConfirm, user}) {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const [fecha, setFecha] = useState(item.fecha);
    const [labelMTD, setLabelMTD] = useState('');

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            periodo: item.periodo,
            lugarMTD: item.zonaIdTM || item.zonaIdTT,
            lugarCompuesto: item.zonaIdTT,
            tipoTarea: item.tipoTrabajo || item.tipoTrabajoC,
            tipoTareaCompuesto: item.tipoTrabajoC,
            checkRepro: item.checkRepro,
            checkFO: (item.tipoTrabajo === 'INSTALACION FO' ||  item.tipoTrabajoC === 'INSTALACION FO'),
            lugaresTM: (item.periodo === 'TT')? item.lugaresTT?.map(lug=>lug.id) : item.lugaresTM?.map(lug=>lug.id),
            lugaresTT: item.lugaresTT?.map(lug=>lug.id),
            cuadrillaLugarOrden: item.cuadrillaLugarOrden,
            observaciones: item?.observaciones,
        }
    });

    const periodo = watch('periodo');
    const lugarMTD = watch('lugarMTD');
    const lugarCompuesto = watch('lugarCompuesto');
    const tipoTarea = watch('tipoTarea');
    const tipoTareaCompuesto = watch('tipoTareaCompuesto');
    const checkRepro = watch('checkRepro');
    const checkFO = watch('checkFO');
    const lugaresTM = watch('lugaresTM');
    const lugaresTT = watch('lugaresTT');

    useEffect(() => {
        register('periodo');
        register('lugarMTD');
        register('tipoTarea');
        register('lugarCompuesto');
        register('tipoTareaCompuesto');
        register('cuadrillaLugarOrden');
        register('observaciones');
        register('checkRepro');
        register('checkFO');
        register('lugaresTM');
        register('lugaresTT');
    }, [register]);

    const handleClose = () => hideModal();

    const onEliminar = () => {
        showModal(ModalConfirm, {
            title: '¿Esta seguro de eliminar la asignacion del lugar?',
            message: 'Una vez echo esto no podra recuperarlo',
            onConfirm: () => {
                CuadrillaLugaresService.destroy(item.id).then((response) => {
                    onConfirm(response);
                    handleClose();
                });
            }
        });
    };

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id,
            cuadrillaDisponibleId: item.cuadrillaDisponibleId,
            fecha: fecha
        };
        if(form.periodo === 'TT' && form.lugaresTM){
            form.lugaresTT = [...form.lugaresTM];
            form.lugaresTM = [];
        }
        setIsLoading(true);
        if (item.id > 0) {
            CuadrillaLugaresService.update(form)
                .then((response) => {
                    onConfirm(response);
                    handleClose();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            CuadrillaLugaresService.register(form)
                .then((response) => {
                    onConfirm(response);
                    handleClose();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const onSelectPeriodo = (opt) => {
        setValue('periodo', opt.value);
        switch (opt.value) {
            case 'TT':
            case 'TM':
            case 'TD':
                setLabelMTD(`Zona: ${opt.label}`);
                break;
            case 'TC':
                setLabelMTD('Zona: Turno Mañana');
                break;
            default:
                break;
        }
    };

    const onChangeCuadrillaRepro = (newCheck) => {
        setValue('checkRepro', newCheck);
    }

    const onChangeCuadrillaFO = (newCheck) => {
        setValue('checkFO', newCheck);
        if(!periodo){
            onSelectPeriodo({label: 'Turno Mañana', value: 'TM'})
        }else if((periodo === 'TD' || periodo === 'TC')){
            onSelectPeriodo({label: 'Turno Mañana', value: 'TM'})
        }
        if(newCheck){
            setValue('lugarMTD', 1);
            setValue('observaciones', 'CUADRILLA PARA INSTALACION DE FO');
            setValue('tipoTarea', 'INSTALACION FO');
            setTimeout(() => {
                setValue('lugaresTM',[1])
            }, 200);
        }else{
            setValue('lugarMTD', null);
            setValue('tipoTarea', 'NORMAL');
            setValue('observaciones', '');
            setTimeout(() => {
                setValue('lugaresTM',[])
            }, 200);
        }
    }

    useEffect(() => {
        if (item.id > 0) {
            setLabelMTD(`Zona: ${item.periodoNombre}`);
        }
    }, []);

    useEffect(() => {
        setValue('lugaresTM',[])
    }, [])

    useEffect(() => {
            setValue('lugaresTM',[])
    }, [lugarMTD])


    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Cuadrilla' : 'Registrar Cuadrilla'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group">
                                <label
                                    className="label-control"
                                    htmlFor="fecha"
                                >
                                    Fecha
                                </label>
                                <DateSingle
                                    id="fecha"
                                    value={fecha}
                                    onChange={setFecha}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="label-control" htmlFor="cuadrillaLugarOrdenL">
                                Orden (opcional)
                                </label>
                                <input
                                    className={`form-control ${errors.cuadrillaLugarOrden? 'is-invalid': ''}`}
                                    id="cuadrillaLugarOrden"
                                    name="cuadrillaLugarOrden"
                                    type="text"
                                    autoComplete="off"
                                    ref={register}
                                />
                                {errors.cuadrillaLugarOrden && (
                                    <div className="invalid-feedback">
                                        {errors.cuadrillaLugarOrden.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <InputCheck
                        className="ml-2 mt-2"
                        label="¿Esta Cuadrilla es FO?"
                        checked={checkFO}
                        onChange={onChangeCuadrillaFO}
                    />
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label
                                    className="label-control"
                                    htmlFor="periodo"
                                >
                                    Periodo
                                </label>
                                <SelectPeriodo
                                    id="periodo"
                                    value={periodo}
                                    isInvalid={Boolean(errors.periodo)}
                                    onChange={onSelectPeriodo}
                                    isFO={checkFO}
                                />
                                {errors.periodo ? (
                                    <div className="default-invalid-feedback">
                                        {errors.periodo?.message}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <Collapse
                        in={['TT', 'TM', 'TC', 'TD'].indexOf(periodo) >= 0}
                    >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="lugarMTD"
                                    >
                                        {labelMTD}
                                    </label>
                                    {user.puesto === "Líder de Ventas Seg. Masivo" ? (
                                    <SelectZona
                                        id="lugarMTD"
                                        value={lugarMTD}
                                        onChange={(opt) => {
                                            setValue('lugarMTD', opt.value);
                                        }}
                                    />
                                    ):(
                                    <SelectZona
                                        id="lugarMTD"
                                        value={lugarMTD}
                                        onChange={(opt) => {
                                            setValue('lugarMTD', opt.value);
                                        }}
                                        disabled={lugaresTM?.length > 0 || checkFO}
                                    />
                                    )}
                                    {errors.lugarMTD && (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarMTD?.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTarea"
                                    >
                                        Tipo de tarea
                                    </label>
                                    <SelectTipoTrabajo
                                        id="tipoTarea"
                                        value={tipoTarea}
                                        isInvalid={Boolean(errors.tipoTarea)}
                                        onChange={(opt) => {
                                            setValue('tipoTarea', opt.value);
                                        }}
                                        itemsFO={checkFO}
                                        disabled={checkFO}
                                    />
                                    {errors.tipoTarea ? (
                                        <div className="default-invalid-feedback">
                                            Tipo de tarea es requerido
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12">
                                <label
                                    className="label-control"
                                    htmlFor="lugaresTML"
                                >
                                    Lugares
                                </label>
                                {user.puesto === "Líder de Ventas Seg. Masivo" ? (
                                    <SelectLugar
                                        isMulti
                                        id="lugaresTM"
                                        zonaId={lugarMTD}
                                        isInvalid={Boolean(errors.lugaresTM)}
                                        placeholder="Seleccione..."
                                        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                        menuPortalTarget={document.body}
                                        value={lugaresTM}
                                        onChange={(opt) => setValue('lugaresTM', opt.map(op=>op.value))}
                                        disabled={checkFO}
                                    />
                                ):(
                                    <SelectLugar
                                        isMulti
                                        id="lugaresTM"
                                        zonaId={lugarMTD}
                                        isInvalid={Boolean(errors.lugaresTM)}
                                        placeholder="Seleccione..."
                                        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                        menuPortalTarget={document.body}
                                        value={lugaresTM}
                                        onChange={(opt) => setValue('lugaresTM', opt.map(op=>op.value))}
                                        disabled={lugarMTD === undefined || checkFO}
                                    />
                                )}
                            </div>
                        </div>
                    </Collapse>
                    <Collapse in={periodo === 'TC'}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="lugarCompuesto"
                                    >
                                        Zona: Turno Tarde
                                    </label>
                                    <SelectZona
                                        id="lugarCompuesto"
                                        value={lugarCompuesto}
                                        isInvalid={Boolean(
                                            errors.lugarCompuesto
                                        )}
                                        onChange={(opt) => {
                                            setValue(
                                                'lugarCompuesto',
                                                opt.value
                                            );
                                        }}
                                        disabled={lugaresTT?.length > 0}
                                    />
                                    {errors.lugarCompuesto ? (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarCompuesto?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTareaCompuesto"
                                    >
                                        Tipo de tarea
                                    </label>
                                    <SelectTipoTrabajo
                                        id="tipoTareaCompuesto"
                                        value={tipoTareaCompuesto}
                                        isInvalid={Boolean(
                                            errors.tipoTareaCompuesto
                                        )}
                                        onChange={(opt) => {
                                            setValue(
                                                'tipoTareaCompuesto',
                                                opt.value
                                            );
                                        }}
                                    />
                                    {errors.tipoTareaCompuesto ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoTareaCompuesto?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12">
                                <label
                                    className="label-control"
                                    htmlFor="lugaresTTL"
                                >
                                    Lugares
                                </label>
                                <SelectLugar
                                    isMulti
                                    id="lugaresTT"
                                    zonaId={lugarCompuesto}
                                    isInvalid={Boolean(errors.lugaresTT)}
                                    placeholder="Seleccione..."
                                    styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                    menuPortalTarget={document.body}
                                    value={lugaresTT}
                                    onChange={(opt) => {
                                        setValue('lugaresTT', opt.map(op=>op.value));
                                    }}
                                    disabled={lugarCompuesto === undefined}
                                />
                            </div>
                        </div>
                    </Collapse>
                    <Collapse in={periodo === 'TC' || periodo === 'TM' || periodo === 'TT' || periodo === 'TD'}>
                        <div className="row">
                            <div className="col-12 col-md-12 mt-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="observacionesL">
                                    Observaciones
                                    </label>
                                    <textarea
                                        className={`form-control ${errors.observaciones? 'is-invalid': ''}`}
                                        id="observaciones"
                                        name="observaciones"
                                        type="textarea"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.observaciones && (
                                        <div className="invalid-feedback">
                                            {errors.observaciones.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <InputCheck
                                className="ml-2 mt-2"
                                label="Esta Cuadrilla es de Reprogramacion"
                                checked={checkRepro}
                                onChange={onChangeCuadrillaRepro}
                            />
                        </div>
                    </Collapse>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    (permisos['api.cuadrillaLugar.lugares.store'] ||
                    permisos['api.cuadrillaLugar.lugares.update']) && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
                {
                    permisos['api.cuadrillaLugar.lugares.destroy'] && (
                    <Button 
                        color="primary" 
                        className="btn btn-danger" 
                        onClick={() => onEliminar()}
                        disabled={!item.id}
                        isLoading={isLoading}
                    >
                        <i className="fas fa-trash"></i>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalCuadrillaLugarEditar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number
    }),
    onConfirm: PropTypes.func
};

ModalCuadrillaLugarEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        fecha: new Date(),
        periodo: null,
        checkRepro: false,
        checkFO: false,
        lugaresTM: [],
        lugaresTT: [],
    },
    onConfirm: () => {}
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(ModalCuadrillaLugarEditar);
