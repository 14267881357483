import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link, useHistory} from 'react-router-dom';
import {
    Button,
    ButtonToggle,
    FormTipoMaterial,
    InputEditor,
    SelectMotivoTarea,
    SelectTipoTarea,
} from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';

const schema = Yup.object({
});

function TareaForm2({
    contacto,
    iniciarContacto,
    habilitarCrearContrato,
    nextStep,
    previousStep
}) {
    const {register, handleSubmit, errors, setValue,watch } = useForm({
        resolver: yupResolver(schema)
    });
    const tipoTareas = [
        {
            name: 'ASISTENCIA',
            value: '1',
        },
        {
            name: 'RETIRO',
            value: '2',
        },        {
            name: 'INSTALACION',
            value: '3',
        }
    ];
    const [motivoTareaOption, setmotivoTareaOption] = useState('');
    const tipoTareaId = watch('tipoTareaId');
    const [template, setTemplate] = useState('');
    const optionsDef = [
        {
            title: 'Contacto',
            subtitle: 'Datos del cliente',
        },
        {
            title: 'Motivo',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Documentacion',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Plan',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Pactar Fecha',
            subtitle: 'Seleccionar Fecha y Turno',
        },
    ];
    useEffect(() => {
        register('tipoTareaId');
        register('motivoTareaId');
        register('motivoTareaObservaciones');
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...contacto,
            ...data,
            
        }; 
        iniciarContacto(form)
        nextStep()
    };
    return (
            <>
                <div className="container mt-2 p-3 border shadow-sm rounded-lg bg-white">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 my-lg-3">
                                <h2>Tareas Parte 2</h2>
                            </div>                          
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTareaId"
                                    >
                                        Tipo de Tarea
                                    </label>
                                    <ButtonToggle 
                                        name="tipoTareaId"
                                        items={tipoTareas}
                                        onChange={(opt) => {
                                            setValue('tipoTareaId', opt);
                                            setTemplate('');
                                            if(opt === "3"){
                                                habilitarCrearContrato(true);
                                            } else {
                                                habilitarCrearContrato(false);
                                            }
                                        }}
                                    />
                                    {errors.tipoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaId"
                                    >
                                        Motivo de la Tarea
                                    </label>
                                    <SelectMotivoTarea
                                        id="motivoTareaId"
                                        value={motivoTareaOption}
                                        tipoTareaId={tipoTareaId}
                                        onChange={(opt) => {
                                            setValue(
                                                'motivoTareaId',
                                                opt
                                            );
                                            setValue(
                                                'motivoTareaObservaciones',
                                                opt.descripcion
                                            );
                                            setmotivoTareaOption(opt);
                                            setTemplate(opt.descripcion);
                                        }}
                                    />
                                    {errors.motivoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.motivoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaObservaciones"
                                    >
                                        Observaciones
                                    </label>
                                        <InputEditor
                                            id="motivoTareaObservaciones"
                                            template={template}
                                            disabled={!(tipoTareaId > 0)}
                                            onChange={(html) => {
                                                setValue(
                                                    'motivoTareaObservaciones',
                                                    html
                                                    );
                                                }}
                                        />
                                        {errors.motivoTareaObservaciones ? (
                                            <div className="default-invalid-feedback">
                                                {
                                                    errors.motivoTareaObservaciones
                                                    ?.message
                                                }
                                            </div>
                                        ) : null}
                                </div>
                            </div>
                        </div>  
                       
                    </form>
                        <div className="col-12 my-lg-4 col-lg-12">
                            <div className="row">

                                <div className="offset-6 col-6 offset-md-8 col-md-4 offset-lg-10 col-lg-2 float-right">
                                    <Button className="btn-secondary" onClick={previousStep}>
                                        Anterior
                                    </Button>
                                    <Button
                                        className="btn btn-primary" 
                                        theme="primary"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </div>
                </div>
            </>
    );
}

TareaForm2.defaultProps = {
    item: {
        motivoTarea: {
            id: 0,
        }
    },
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
    habilitarCrearContrato: (is) => dispatch(ContactosAction.habilitarCrearContrato(is)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm2);