import React, {useState, useEffect} from 'react';
import PropTypes, { array } from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { Button,InputCheck} from '@app/components/index';
import CalendarioService from '@app/services/calendario.service';
const schema = Yup.object({
});

function ModalFiltroCalendar({show, hideModal, item, onConfirm}) {

    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const [arrayTipoTarea,setArrayTipoTarea]= useState(String(item?.tipoTareaId).split(','));
    const [arrayCategoriaTarea,setArrayCategoriaTarea]= useState(String(item?.tareaCategoria).split(','));
    const [arrayEstadoTarea,setArrayEstadoTarea]= useState(String(item?.estadoTareaId).split(','));

    const onCheckTipoTarea = (check,tipo)=>{
        if(check){
            setArrayTipoTarea([...(arrayTipoTarea.filter(arr=>arr!==tipo)),tipo])
        } else {
            setArrayTipoTarea([...(arrayTipoTarea.filter(arr=>arr!==tipo))])
        }
    }
    const onCheckCategoriaTarea = (check,tipo)=>{
        if(check){
            setArrayCategoriaTarea([...(arrayCategoriaTarea.filter(arr=>arr!==tipo)),tipo])
        } else {
            setArrayCategoriaTarea([...(arrayCategoriaTarea.filter(arr=>arr!==tipo))])
        }
    }
    const onCheckEstadoTarea = (check,tipo)=>{
        if(check){
            setArrayEstadoTarea([...(arrayEstadoTarea.filter(arr=>arr!==tipo)),tipo])
        } else {
            setArrayEstadoTarea([...(arrayEstadoTarea.filter(arr=>arr!==tipo))])
        }
    }


    const handleClose = () => hideModal();

    const handlelLimpiarFiltro = () => {
        const formLF = {
            tareaCategoria:'NORMAL,VIP 1,VIP 2,VIP 3',
            tipoTareaId: '1,2,3',
            estadoTareaId: '1,2,3,5',
        }
        onConfirm(formLF);
        hideModal();
    };

    const onSubmit = (data) => {
        const form = {
            ...data,
            tipoTareaId: arrayTipoTarea.join(','),
            tareaCategoria: arrayCategoriaTarea.join(','),
            estadoTareaId: arrayEstadoTarea.join(',')
        };
         onConfirm(form);
         hideModal();

    };

    useEffect(() => {
    }, []);
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                     Filtros
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5 className="mb-4">Categoria de tareas</h5>
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck
                                id="Normal"
                                label="NORMAL"
                                value="NORMAL"
                                checked={arrayCategoriaTarea.indexOf('NORMAL')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'NORMAL')}
                            />
                        </div>
                        <div className="col-4">
                            <InputCheck
                                label="PYME 1"
                                value="PYME 1"
                                checked={arrayCategoriaTarea.indexOf('PYME 1')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'PYME 1')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="PYME 2"
                                value="PYME 2"
                                checked={arrayCategoriaTarea.indexOf('PYME 2')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'PYME 2')}
                            />
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck
                                label="PYME 3"
                                value="PYME 3"
                                checked={arrayCategoriaTarea.indexOf('PYME 3')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'PYME 3')}
                            />
                        </div>
                        <div className="col-4">
                            <InputCheck
                                label="PYME 4"
                                value="PYME 4"
                                checked={arrayCategoriaTarea.indexOf('PYME 4')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'PYME 4')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="VIP 1"
                                value="VIP 1"
                                checked={arrayCategoriaTarea.indexOf('VIP 1')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'VIP 1')}
                            />
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck
                                label="VIP 2"
                                value="VIP 2"
                                checked={arrayCategoriaTarea.indexOf('VIP 2')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'VIP 2')}
                            />
                        </div>
                        <div className="col-4">
                            <InputCheck
                                label="VIP 3"
                                value="VIP 3"
                                checked={arrayCategoriaTarea.indexOf('VIP 3')>=0}
                                onChange={(check)=> onCheckCategoriaTarea(check,'VIP 3')}
                            />
                        </div>
                        <div className="col-12 text-center">
                            <h5 className="mb-4">Tipos de tareas</h5>
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck 
                                label="INSTALACION"
                                value="3"
                                checked={arrayTipoTarea.indexOf('3')>=0}
                                onChange={(check)=> onCheckTipoTarea(check,'3')}
                            />
                        </div>
                        <div className="col-4">
                            <InputCheck
                                label="ASISTENCIA"
                                value="1"
                                checked={arrayTipoTarea.indexOf('1')>=0}
                                onChange={(check)=> onCheckTipoTarea(check,'1')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="RETIRO"
                                value="2"
                                checked={arrayTipoTarea.indexOf('2')>=0}
                                onChange={(check)=> onCheckTipoTarea(check,'2')}
                            />
                        </div>
                        <div className="col-12 text-center">
                            <h5 className="mb-4">Estados de tareas</h5>
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck
                                label="EN ESPERA"
                                value="1"
                                checked={arrayEstadoTarea.indexOf('1')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'1')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="A DESIGNAR"
                                value="2"
                                checked={arrayEstadoTarea.indexOf('2')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'2')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="ASIGNADAS"
                                value="3"
                                checked={arrayEstadoTarea.indexOf('3')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'3')}
                            />
                        </div>
                        <div className="offset-1 col-4">
                            <InputCheck
                                label="REPROGRAMADAS"
                                value="4"
                                checked={arrayEstadoTarea.indexOf('4')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'4')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="VENCIDAS"
                                value="5"
                                checked={arrayEstadoTarea.indexOf('5')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'5')}
                            />
                        </div>
                        <div className="col-3">
                            <InputCheck
                                label="CANCELADAS"
                                value="6"
                                checked={arrayEstadoTarea.indexOf('6')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'6')}
                            />
                        </div>
                        <div className="offset-1 col-5">
                            <InputCheck
                                label="PARA REPROGRAMAR"
                                value="7"
                                checked={arrayEstadoTarea.indexOf('7')>=0}
                                onChange={(check)=> onCheckEstadoTarea(check,'7')}
                            />
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-4 mr-lg-4">
                            <Button theme="gray" onClick={handlelLimpiarFiltro}>
                                LIMPIAR FILTRO
                            </Button>
                        </div>
                        <div className="col-3 offset-lg-3 col-lg-2">
                        <Button theme="default" onClick={handleClose}>
                            CANCELAR
                        </Button>
                        </div>
                        <div className="col-3 ml-lg-1 col-lg-2">
                        <Button
                            theme="primary"
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                        >
                            ACEPTAR
                        </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

ModalFiltroCalendar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number
    }),
    onConfirm: PropTypes.func
};

ModalFiltroCalendar.defaultProps = {
    show: false,
    item: {
        tareaCategoria: 'NORMAL,VIP 1,VIP 2,VIP 3',
        tipoTareaId: '1,2,3',
        estadoTareaId: '1,2,3,5'
    },
    onConfirm: () => {}
};

export default ModalFiltroCalendar;
